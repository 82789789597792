<template>
  <div class="stock-details main-panel large">
    <h3 class="panel-title">出入库明细</h3>
    <div class="top">
      <el-form inline>
        <el-form-item label="产品">
          <el-select :size="controlSize" filterable v-model="form.product" placeholder="请选择产品">
            <el-option v-for="item in products" :key="item.id" :label="item.pname" :value="item.id">
              <span style="float: left">{{ item.pname }}</span>
              <span style="float: right; color: #8492a6; font-size: 12px">{{ item.fabricName }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库">
          <el-select :size="controlSize" filterable v-model="form.department" placeholder="请选择仓库">
            <el-option v-for="item in departments" :key="item.id" :label="item.wareName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
              v-model="form.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini" />
        </el-form-item>
        <el-form-item>
          <el-button type="danger" :size="controlSize" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="main">
      <el-table :data="list" height="500">
        <el-table-column align="center" label="日期">
          <template #default="scope">{{ $day(scope.row.createtime).format("YYYY.MM.DD") }}</template>
        </el-table-column>
        <el-table-column align="center" prop="productName" label="产品名"></el-table-column>
        <el-table-column align="center" prop="middlenumber" label="数量"></el-table-column>
        <el-table-column align="center" prop="departmentName" label="仓库"></el-table-column>
        <el-table-column align="center" label="类别">
          <template #default="scope">{{ ["", "入库", "出库"][scope.row.type] }}</template>
        </el-table-column>
        <el-table-column align="center" prop="content" label="来源单据"></el-table-column>

        <template #append>
          <div class="load-more"
               :class="{'no-more': noMore}"
               v-if="list.length"
               @click="getList">{{ noMore ? "没有数据了~" : "点击加载下一页" }}</div>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      controlSize: "mini",
      form: {
        warehouse: {},
        name: "",
        dateRange: null,
        currentPage: 1,
        pageSize: 50
      },

      products: [],
      departments: [],
      list: [],
      noMore: false
    }
  },

  async mounted() {
    this.products = await this.$getList.getProducts()
    this.departments = await this.$getList.getDepartments()
    await this.getList()
  },

  methods: {
    async search() {
      this.noMore = false;
      this.form.currentPage = 1;
      this.list = []
      await this.getList();
    },

    async getList() {
      if (this.noMore) return false;
      this.$showLoading();
      const sendData = {
        pname: this.form.name,
        currentPage: this.form.currentPage,
        pageSize: this.form.pageSize
      }
      if (this.form.warehouse.id) sendData.warehouseId = this.form.warehouse.id;
      if (this.form.dateRange !== null) {
        sendData.startDate = this.$day(this.form.dateRange[0]).format("YYYY-MM-DD");
        sendData.endDate = this.$day(this.form.dateRange[1]).format("YYYY-MM-DD");
      }
      const res = await this.$api.Report.Stock.getDetails(sendData).catch(() => {this.$message.error("查询失败");});
      this.$hideLoading();
      if (res?.code === 200) {
        this.form.currentPage += 1;
        this.list.push(...res.data.data);
        if (this.list.length >= res.data.total) {
          this.noMore = true;
        }
        return true;
      }
      this.$message.error("查询失败");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "stock-details";
</style>
